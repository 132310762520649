import React, { useState } from 'react'
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomButton2 from "../../general-components/CustomButton2.component";
import Accordion from "../../general-components/AccodionTwo";
import CustomViewLabel from '../../general-components/CustomViewLabel.component';
import CustomViewTextArea from "../../general-components/CustomViewTextArea"
import CustomInputWithFileViewer from '../../general-components/CustomInputWithFileViewer.component';
import CustomFileViewer from '../../general-components/CustomFileViewer.component';

export default function EmployeeProfileView({ selectedEmployeeData, onCancel }) {
  return (
    <>
      <div className="bg-white min-w-[350px] pb-5 rounded-lg overflow-visible">
        <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
          <h1 className="text-l font-weight-[300]">View Employee</h1>
          <div className="flex items-center text-sm"></div>
        </div>

        <div className="p-4 gap-4 max-h-[70vh] overflow-y-auto max-w-full w-[1100px]">
          <Accordion title={"Employee Details"}>
            <div className="grid grid-cols-1  gap-4 gap-y-4   md:grid-cols-3 ">
              <CustomViewLabel label="Employee Name" value={selectedEmployeeData?.userName ?? '-'} />
              <CustomViewLabel label="Email" value={selectedEmployeeData?.emailId ?? '-'} />
              <CustomViewLabel label="Mobile Number" value={selectedEmployeeData?.mobileNumber ?? '-'} />
              <CustomViewLabel label="Department" value={selectedEmployeeData?.departmentName ?? '-'} />
              <CustomViewLabel label="Role" value={selectedEmployeeData?.roleName ?? '-'} />
              <CustomViewLabel label="Reporting To" value={selectedEmployeeData?.reportingToName ?? '-'} />
              <CustomViewLabel label="Company" value={selectedEmployeeData?.companyName ?? '-'} />
              {selectedEmployeeData?.branchList && (
                <CustomViewLabel
                  label="Branch"
                  value={selectedEmployeeData.branchList.map(branch => branch.branchName).join(', ')}
                />
              )}
              {/* {new Date(dsData.createdDate).toLocaleDateString('en-GB')} */}
              <CustomViewLabel label="Date of Birth" value={selectedEmployeeData?.dateOfBirth ?? '-'} />
              <CustomViewLabel label="Date of Joining" value={selectedEmployeeData?.dateOfJoining ?? '-'} />
              <CustomViewLabel label="Blood Group" value={selectedEmployeeData?.bloodGroup ?? '-'} />
              <CustomViewLabel label="Gender" value={selectedEmployeeData?.genderName ?? '-'} />
              <CustomViewLabel label="Marital Status" value={selectedEmployeeData?.maritalStatus ?? '-'} />
              <CustomViewLabel label="Company Number" value={selectedEmployeeData?.companyNumber ?? '-'} />
              {/* <CustomViewLabel label="Password" value={selectedEmployeeData?.password ?? '-'} /> */}
            </div>
          </Accordion>

          <Accordion title="Permanent Address">
            <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
              <CustomViewTextArea label="Address" value={selectedEmployeeData?.addressLine ?? '-'} />
              <CustomViewLabel label="Country" value={selectedEmployeeData?.countryName ?? '-'} />
              <CustomViewLabel label="State" value={selectedEmployeeData?.stateName ?? '-'} />
              <CustomViewLabel label="District" value={selectedEmployeeData?.districtName ?? '-'} />
              <CustomViewLabel label="Pincode" value={selectedEmployeeData?.pincode ?? '-'} />
            </div>
          </Accordion>

          <Accordion title="Temporary Address">
            <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
              <CustomViewTextArea label="Address" value={selectedEmployeeData?.temporaryAddress ?? '-'} />
              <CustomViewLabel label="Country" value={selectedEmployeeData?.temporary_CountryName ?? '-'} />
              <CustomViewLabel label="State" value={selectedEmployeeData?.temporary_StateName ?? '-'} />
              <CustomViewLabel label="District" value={selectedEmployeeData?.temporary_DistrictName ?? '-'} />
              <CustomViewLabel label="Pincode" value={selectedEmployeeData?.temporary_Pincode ?? '-'} />
            </div>
          </Accordion>

          <Accordion title="Emergency Contact">
            <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
              <CustomViewLabel label="Emergency Name" value={selectedEmployeeData?.emergencyName ?? '-'} />
              <CustomViewLabel label="Emergency Contact" value={selectedEmployeeData?.emergencyContactNumber ?? '-'} />
              <CustomViewLabel label="Emergency Relation" value={selectedEmployeeData?.emergencyRelation ?? '-'} />
            </div>
          </Accordion>

          <Accordion title="Other Details">
            <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
              {selectedEmployeeData?.userOtherDetailsList && selectedEmployeeData.userOtherDetailsList.map(detail => (
                <React.Fragment key={detail.id}>
                  <CustomViewLabel label="Past Company Name" value={detail.pastCompanyName ?? '-'} />
                  <CustomViewLabel label="Total Exp" value={detail.totalExp ?? '-'} />
                  <CustomViewLabel label="Remarks" value={detail.remark ?? '-'} />
                </React.Fragment>
              ))}

              <div>
                <CustomInputWithFileViewer
                  label="AADHAAR Number"
                  fileURL={selectedEmployeeData?.aadharImageURL}
                  fileName={selectedEmployeeData?.aadharOriginalFileName}
                  fileNumber={selectedEmployeeData?.aadharNumber}
                />
              </div>

              <div>
                <CustomInputWithFileViewer
                  label="PAN No."
                  fileURL={selectedEmployeeData?.panCardImageURL}
                  fileName={selectedEmployeeData?.panCardOriginalFileName}
                  fileNumber={selectedEmployeeData?.panNumber}
                />
              </div>
              <div>
                <CustomInputWithFileViewer
                  label="Other Proof"
                  fileURL={selectedEmployeeData?.otherProofImageURL}
                  fileName={selectedEmployeeData?.otherProofOriginalFileName}
                  fileNumber={selectedEmployeeData?.otherProof}
                />
              </div>
              <div>
                <CustomFileViewer label="Profile Photo"
                  fileURL={selectedEmployeeData?.profileImageURL}
                  fileName={selectedEmployeeData?.profileOriginalFileName}
                />
              </div>
              <CustomViewLabel label="Is Web User" value={selectedEmployeeData?.isMobileUser ? 'Yes' : 'No'} />
              <CustomViewLabel label="Is Mobile User" value={selectedEmployeeData?.isWebUser ? 'Yes' : 'No'} />
              <CustomViewLabel label="Is Active" value={selectedEmployeeData?.isActive ? 'True' : 'False'} />

            </div>
          </Accordion>
        </div>

        <div className="flex justify-center gap-5 mt-5">
          <div>
            <CustomButton2
              label={"Cancel"}
              variant="outlined"
              className="text-first"
              onClick={onCancel}
            />
          </div>
        </div>
      </div>
    </>
  );
}

