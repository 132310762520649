// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import Select from 'react-select';
import Button from "@mui/material/Button";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomDate from "../../general-components/CustomDate.component";
import Accordion from "../../general-components/AccodionTwo";
import CustomTextArea from "../../general-components/CustomTextArea.component";
import CustomNumber from "../../general-components/CustomNumber.component";
import CustomCheckBox3 from '../../general-components/CustomCheckBox3.component';
import CustomPassword from "../../general-components/CustomPassword.component";
import CustomButton2 from "../../general-components/CustomButton2.component";
import CustomImgUpload from "../../general-components/CustomImgUpload.component";
import CustomPinCodeNumber from "../../general-components/CustomPinCodeNumber.component";
import CustomGlobalUpload from "../../general-components/CustomGlobalUpload.component";

// ICONS
import { FiPlus, FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../assets/css/selectbar.css';
import { useAuth } from "../../../context";
import CustomSelect2 from "../../general-components/CustomSelect2.component";
import { Tooltip } from "@mui/material";

export default function EmpHistoryEdit({ selectedEmployeeData, onCancel }) {
    const { user } = useAuth();

    const [statusEmployeeAdd, setStatusEmployeeAdd] = useState(true);
    let [checkboxWebUserEmployeeAdd, setCheckboxWebUserEmployeeAdd] = useState(true);
    let [checkboxMobileUserEmployeeAdd, setCheckboxMobileUserEmployeeAdd] = useState(true);
    let [isSameAsPermanent, setIsSameAsPermanent] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassWord] = useState("");
    console.log("🚀 ~ file: EmployeeProfile.jsx:75 ~ EmployeeProfile ~ password:", password)
    const [dob, setDob] = useState("");
    const [doj, setDoj] = useState("");
    const [compNo, setCompNo] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [pincode, setPincode] = useState("");
    const [pincodeTwo, setPincodeTow] = useState("");
    const [emgName, setEmgName] = useState("");
    const [emgNo, setEmgNo] = useState("");
    const [emgRel, setEmgRel] = useState("");

    const [adharNo, setAdharNo] = useState({
        inputValue: '',
        fileName: '',
        fileBase64: ''
    });

    const [panNo, setPanNo] = useState({
        inputValue: '',
        fileName: '',
        fileBase64: ''
    });

    const [otherProof, setOtherProof] = useState({
        inputValue: '',
        fileName: '',
        fileBase64: ''
    });
    const [profileImage, setProfileImage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [adhaarImg, setAdhaarImg] = useState("");
    const [adhaarName, setAdhaarName] = useState("");
    const [adhaarNumber, setAdhaarNumber] = useState("");

    const [panFileImg, setPanFileImg] = useState("");
    const [panFileName, setPanFileName] = useState("");
    const [panFileNumber, setPanFileNumber] = useState("");

    const [otherFileImg, setOtherFileImg] = useState("");
    const [otherFileName, setOtherFileName] = useState("");
    const [otherFileNumber, setOtherFileNumber] = useState("");

    const [reportingEmployees, setReportingEmployees] = useState([]);
    console.log("🚀 ~ file: EmployeeProfile.jsx:109 ~ EmployeeProfile ~ reportingEmployees:", reportingEmployees)
    const [selectedReportingEmployees, setSelectedReportingEmployees] = useState(0);
    const [reportingTo, setreportingTo] = useState(0);
    console.log("🚀 ~ file: EmployeeProfile.jsx:111 ~ EmployeeProfile ~ reportingTo:", reportingTo)
    const [selectedReportingEmployeesName, setSelectedReportingEmployeesName] = useState("");

    const [selectedRegion, setSelectedRegion] = useState("");
    const [selectedStateName, setSelectedStateName] = useState("");
    const [selectedDistrictName, setSelectedDistrictName] = useState("");
    const [selectedCountryName, setSelectedCountryName] = useState(null);
    const [selectedCountryNameTemp, setSelectedCountryNameTemp] = useState(null);
    const [selectedStateTempName, setSelectedStateTempName] = useState("");
    const [selectedDistrictTempName, setSelectedDistrictTempName] = useState("");

    const [genderData, setGenderData] = useState([]);
    const [selectedGender, setSelectedGender] = useState("");
    const [selectedGenderName, setSelectedGenderName] = useState("");
    const [deptData, setDeptData] = useState([]);
    const [selecteDept, setSelectedDept] = useState("");
    const [selecteDeptName, setSelectedDeptName] = useState("");

    const [roleData, setRoleData] = useState([]);
    const [selecteRole, setSelectedRole] = useState(0);
    console.log("🚀 ~ file: EmployeeProfile.jsx:145 ~ EmployeeProfile ~ selecteRole:", selecteRole)
    const [selecteRoleName, setSelectedRoleName] = useState("");
    console.log("🚀 ~ file: EmployeeProfile.jsx:145 ~ EmployeeProfile ~ selecteRoleName:", selecteRoleName)

    const [companyData, setCompanyData] = useState([]);
    const [selecteCompany, setSelectedCompany] = useState("");
    const [selecteCompanyName, setSelectedCompanyName] = useState("");

    const [branchData, setBranchData] = useState([]);
    const [selecteBranch, setSelectedBranch] = useState([]);
    const [selecteBranchName, setSelectedBranchName] = useState([]);
    const [branchError, setBranchError] = useState(""); // State for branch error

    const [mrgStatusData, setMrgStatusData] = useState([]);
    const [selecteMrgStatus, setSelectedMrgStatus] = useState("");
    const [selecteMrgStatusName, setSelectedMrgStatusName] = useState("");

    const [bGroupData, setBGroupData] = useState([]);
    const [selecteBGroup, setSelectedBGroup] = useState("");
    const [selecteBGroupName, setSelectedBGroupName] = useState("");

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const datacomp = { searchText: "", companyId: 0, isActive: true, departmentId: 0 };

    const [pastComp, setPastComp] = useState("");
    const [totalExp, setTotalExp] = useState("");
    const [totalExpErr, setTotalExpErr] = useState("");
    const [remark, setRemark] = useState("");
    const [entries, setEntries] = useState([]);
    console.log("🚀 ~ file: EmployeeProfile.jsx:173 ~ EmployeeProfile ~ entries:", entries)
    const [editIndex, setEditIndex] = useState(-1); // Track the index of the entry being edited, -1 means no edit mode

    // const [fileName, setFileName] = useState("");
    const [fileOriginalName, setFileOriginalName] = useState("");
    const [fileUrl, setFileUrl] = useState("");

    const [allTerData, setAllTerData] = useState([]);
    const [allTerDataTemp, setAllTerDataTemp] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedDistrict, setSelectedDistrict] = useState(null);

    const [selectedCountryTemp, setSelectedCountryTemp] = useState(null);
    const [selectedStateTemp, setSelectedStateTemp] = useState(null);
    const [selectedDistrictTemp, setSelectedDistrictTemp] = useState(null);

    const handlePastCompChange = (e) => setPastComp(e.target.value);
    const handleTotalExpChange = (e) => setTotalExp(e.target.value);
    const handleRemarkChange = (e) => setRemark(e.target.value);

    const validateDigitsOnly = (value) => {
        return /^\d+$/.test(value);
    };

    const handleAddRow = () => {
        let hasError = false;

        if (!validateDigitsOnly(totalExp)) {
            setTotalExpErr("Only Allow Numbers");
            hasError = true;
        } else {
            setTotalExpErr("");
        }

        if (hasError) return;

        if (editIndex === -1) {
            // Adding a new entry
            const newEntry = {
                pastCompanyName: pastComp || "",
                totalExp,
                remark,
            };
            setEntries((prevEntries) => [...prevEntries, newEntry]);
        } else {
            // Editing an existing entry
            const updatedEntries = [...entries];
            updatedEntries[editIndex] = {
                pastCompanyName: pastComp || "",
                totalExp,
                remark,
            };
            setEntries(updatedEntries);
            setEditIndex(-1); // Exit edit mode
        }
        // Clear input fields
        setPastComp("");
        setTotalExp("");
        setRemark("");
    };

    const handleEditRow = (index) => {
        // Set form fields with values from the entry to be edited
        const entryToEdit = entries[index];
        setPastComp(entryToEdit.pastCompanyName);
        setTotalExp(entryToEdit.totalExp);
        setRemark(entryToEdit.remark);
        setEditIndex(index); // Set the index of the entry being edited
    };

    const handleDeleteRow = async (indexToDelete) => {
        const userIdToDelete = entries[indexToDelete].id; // Assuming 'id' is the field that stores the user ID

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/User/DeleteUserOtherDetails`, {
                method: 'POST', // Update to the correct method according to API docs
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ id: userIdToDelete }), // Add ID to request body
            });

            if (!response.ok) {
                throw new Error('Failed to delete user details');
            }
            // If the delete is successful, remove the entry from the state
            setEntries(entries.filter((_, index) => index !== indexToDelete));
            if (editIndex === indexToDelete) {
                setEditIndex(-1); // Exit edit mode if deleting the currently edited entry
            }
        } catch (error) {
            console.error('Error deleting user details:', error);
        }
    };

    const transformEntriesToUserDetailsList = () => {
        return entries.map((entry, index) => ({
            id: index,
            employeeId: 0,  // Assuming employeeId is 0 for all entries; adjust as necessary
            pastCompanyName: entry.pastCompanyName || "",
            totalExp: entry.totalExp,
            remark: entry.remark
        }));
    };
    console.log("🚀 ~ file: EmployeeProfile.jsx:271 ~ returnentries.map ~ entries:", entries)

    useEffect(() => {
        console.log("transformed_data-->", transformEntriesToUserDetailsList());
    }, [entries]);

    const fetchData = async (url, setData, errorMsg) => {
        setIsLoading(true);
        try {
            const response = await axios.post(url, datacomp, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetGenderList`, setGenderData, "Error fetching Gender Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetMaritalStatusList`, setMrgStatusData, "Error fetching Marital Status Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetBloodGroupList`, setBGroupData, "Error fetching Blood Group Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/Profile/GetDepartmentList`, setDeptData, "Error fetching Department Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/Branch/GetBranchList`, setBranchData, "Error fetching Branch Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/Company/GetCompanyList`, setCompanyData, "Error fetching Company Data");

        fetchData(`${process.env.REACT_APP_BASE_URL}/Territory/GetTerritoriesList`, setAllTerData, "Error fetching Territories Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/Territory/GetTerritoriesList`, setAllTerDataTemp, "Error fetching Territories Data");
    }, []);

    const handleRoleChange = async (selectedOption) => {
        console.log("🚀 ~ file: EmployeeProfile.jsx:321 ~ handleRoleChange ~ selectedOption:", selectedOption);

        if (selectedOption) {
            const roleId = selectedOption.value;
            const roleName = selectedOption.label;

            setSelectedRole(roleId);
            setSelectedRoleName(roleName);

            try {
                // Fetch reporting to employee list for the selected role
                const reportingToResponse = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/Profile/GetReportingToEmpListForSelectList`,
                    { roleId, countryId: 0, isActive: true },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (reportingToResponse.data.isSuccess && Array.isArray(reportingToResponse.data.data)) {
                    setReportingEmployees(reportingToResponse.data.data);
                } else {
                    console.log("Invalid response format for reporting employees");
                }
            } catch (error) {
                toast.error("Error fetching reporting employees");
                console.error("Error fetching reporting employees:", error);
            }
        } else {
            // Handle the case when the select is cleared
            setSelectedRole(null); // Reset to null when cleared
            setSelectedRoleName(''); // Clear the department name label
        }
    };

    const handleGenderChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedGender(selectedOption.value);
            setSelectedGenderName(selectedOption.label); // Update the department name label
        } else {
            setSelectedGender(null); // Reset to null when cleared
            setSelectedGenderName(''); // Clear the department name label
        }
    }

    const handleDeptChange = async (selectedOption) => {
        if (selectedOption) {
            const deptId = selectedOption.value;
            setSelectedDept(deptId);
            setSelectedDeptName(selectedOption.label); // Update the department name label

            try {
                const roleResponse = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/Profile/GetRoleList`,
                    { departmentId: deptId },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (roleResponse.data.isSuccess && Array.isArray(roleResponse.data.data)) {
                    setRoleData(roleResponse.data.data);
                } else {
                    console.log("Invalid response format for roles");
                }
            } catch (error) {
                toast.error("Error fetching roles for the selected department");
                console.error("Error fetching roles:", error);
            }
        } else {
            setSelectedDept(null); // Reset to null when cleared
            setSelectedDeptName(''); // Clear the department name label
            setRoleData([]);
        }
    }

    const handleReportingToChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedReportingEmployees(selectedOption.value);
            setreportingTo(selectedOption.value)
            setSelectedReportingEmployeesName(selectedOption.label);
        } else {
            setSelectedReportingEmployees(null);
            setreportingTo(null)
            setSelectedReportingEmployeesName('');
        }
    }

    const handleCompanyChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedCompany(selectedOption.value);
            setSelectedCompanyName(selectedOption.label); // Update the department name label
        } else {
            setSelectedCompany(null); // Reset to null when cleared
            setSelectedCompanyName(''); // Clear the department name label
        }

    }

    const handleCountryChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedCountry(selectedOption.value);
            setSelectedCountryName(selectedOption.label);
            setSelectedState(null); // Reset state when country changes
            setSelectedDistrict(null); // Reset district when country changes
        } else {
            setSelectedCountry(null);
            setSelectedCountryName("");
            setSelectedState(null); // Reset state when country changes
            setSelectedDistrict(null); // Reset district when country changes
        }
    }

    const handleStateChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedState(selectedOption.value);
            setSelectedStateName(selectedOption.label);
            setSelectedDistrict(null); // Reset district when state changes
        } else {
            setSelectedState(null);
            setSelectedStateName("");
            setSelectedDistrict(null); // Reset district when state changes
        }
    }

    const handleDistrictChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedDistrict(selectedOption.value);
            setSelectedDistrictName(selectedOption.label);
        } else {
            setSelectedDistrict(null);
            setSelectedDistrictName("");
        }
    }

    const handleCountryChangeTemp = (selectedOption) => {
        if (selectedOption) {
            setSelectedCountryTemp(selectedOption.value);
            setSelectedStateTemp(null); // Reset state when country changes
            setSelectedDistrictTemp(null); // Reset district when country changes
            setSelectedCountryNameTemp(selectedOption.label);
        } else {
            setSelectedCountryTemp(null);
            setSelectedStateTemp(null); // Reset state when country changes
            setSelectedDistrictTemp(null); // Reset district when country changes
            setSelectedCountryNameTemp("");
        }
    }

    const handleStateChangeTemp = (selectedOption) => {
        if (selectedOption) {
            setSelectedStateTemp(selectedOption.value);
            setSelectedStateTempName(selectedOption.label);
            setSelectedDistrictTemp(null); // Reset district when state changes
        } else {
            setSelectedStateTemp(null);
            setSelectedStateTempName('');
            setSelectedDistrictTemp(null); // Reset district when state changes
        }
    }

    const handleDistrictChangeTemp = (selectedOption) => {
        if (selectedOption) {
            setSelectedDistrictTemp(selectedOption.value);
            setSelectedDistrictTempName(selectedOption.label); // Update the department name label
        } else {
            setSelectedDistrictTemp(null); // Reset to null when cleared
            setSelectedDistrictTempName(''); // Clear the department name label
        }
    }

    useEffect(() => {
        if (isSameAsPermanent) {
            setAddress2(address1);
            setSelectedCountryTemp(selectedCountry);
            setSelectedStateTemp(selectedState);
            setSelectedDistrictTemp(selectedDistrict);
            setSelectedCountryNameTemp(selectedCountryName);
            setSelectedStateTempName(selectedStateName);
            setSelectedDistrictTempName(selectedDistrictName);
            setPincodeTow(pincode);
        } else {
            setAddress2("");
            setSelectedCountryTemp(null);
            setSelectedStateTemp(null);
            setSelectedDistrictTemp(null);
            setSelectedCountryNameTemp("");
            setSelectedStateTempName("");
            setSelectedDistrictTempName("");
            setPincodeTow("");
        }
    }, [isSameAsPermanent, address1, pincode, selectedCountry, selectedState, selectedDistrict, selectedCountryName, selectedStateName, selectedDistrictName]);

    const handleBranchChange = (selectedOptions) => {
        const ids = selectedOptions ? selectedOptions.map(option => option.value) : [];
        const names = selectedOptions ? selectedOptions.map(option => option.label) : [];

        setSelectedBranch(ids);
        setSelectedBranchName(names);
        if (selectedOptions.length > 0) {
            setBranchError(""); // Clear error if a branch is selected
        }
    }

    const handleMrgStatusChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedMrgStatus(selectedOption.value);
            setSelectedMrgStatusName(selectedOption.label); // Update the department name label
        } else {
            setSelectedMrgStatus(null); // Reset to null when cleared
            setSelectedMrgStatusName(''); // Clear the department name label
        }
    }

    const handleBGroupChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedBGroup(selectedOption.value);
            setSelectedBGroupName(selectedOption.label); // Update the department name label
        } else {
            setSelectedBGroup(null); // Reset to null when cleared
            setSelectedBGroupName(''); // Clear the department name label
        }
    }

    const [mobile, setMobile] = useState('');
    const [uname, setUname] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [cNameError, setCNameError] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [emailError, setEmailError] = useState('');

    const validateMobile = (value) => {
        if (!value) {
            setMobileError('Mobile number is required');
            return false;
        } else if (!/^[0-9\b]+$/.test(value)) {
            setMobileError('Mobile number only allows numbers');
            return false;
        } else {
            setMobileError('');
            return true;
        }
    };

    const validateCName = (value) => {
        if (!value) {
            setCNameError('Employee Name is required');
            return false;
        } else {
            setCNameError('');
            return true;
        }
    };

    const validateEmail = (value) => {
        if (!value) {
            setEmailError('Email is required');
            return false;
        } else if (!/\S+@\S+\.\S+/.test(value)) {
            setEmailError('Email is invalid');
            return false;
        } else {
            setEmailError('');
            return true;
        }
    };

    const validateBranch = () => {
        if (selecteBranch.length === 0) {
            setBranchError("Please select at least one branch.");
            return false;
        }
        return true;
    };

    const handleChangeMobile = (value) => {
        setMobile(value);
        validateMobile(value);
        checkFormValidity(value, uname, email); // Pass all three values for validation
    };

    const handleChangeUname = (value) => {
        setUname(value);
        validateCName(value);
        checkFormValidity(mobile, value, email); // Pass all three values for validation
    };

    const handleChangeEmail = (value) => {
        setEmail(value);
        validateEmail(value);
        checkFormValidity(mobile, uname, value); // Pass all three values for validation
    };

    const checkFormValidity = (mobile, uname, email) => {
        setIsFormValid(validateMobile(mobile) && validateCName(uname) && validateEmail(email));
    };

    const handleEmployeeSubmit = async (e, userDetailsList) => {
        console.log("🚀 ~ file: EmployeeProfile.jsx:460 ~ handleEmployeeSubmit ~ userDetailsList:", userDetailsList)
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        setIsLoading(true);
        const isMobileValid = validateMobile(mobile);
        const isNameValid = validateCName(uname);
        const isNameEmail = validateEmail(email);
        // const isBranchValid = validateBranch();


        if (!isMobileValid || !isNameValid || !isNameEmail) {
            setIsLoading(false);
            return;
        }
        try {
            const requestBody = {
                id: selectedEmployeeData.id,
                companyId: selecteCompany || 0,
                departmentId: selecteDept || 0,
                userType: "EMP",
                userName: uname || "",
                mobileNumber: mobile || "",
                emailId: email || "",
                roleId: selecteRole || 0,
                genderId: selectedGender || 0,
                maritalStatusId: selecteMrgStatus || 0,
                companyNumber: compNo || "",
                addressLine: address1 || "",
                countryId: selectedCountry || 0,
                stateId: selectedState || 0,
                districtId: selectedDistrict || 0,
                pincode: pincode || "",
                isSameAsPermanent: isSameAsPermanent || false,
                temporaryAddress: address2 || "",
                temporary_CountryId: selectedCountryTemp || 0,
                temporary_StateId: selectedStateTemp || 0,
                temporary_DistrictId: selectedDistrictTemp || 0,
                temporary_Pincode: pincodeTwo || "",
                dateOfBirth: dob || "2024-07-06T05:40:43.228Z",
                dateOfJoining: doj || "2024-07-06T05:40:43.228Z",
                emergencyName: emgName || "",
                emergencyRelation: emgRel || "",
                emergencyContactNumber: emgNo || "",
                bloodGroupId: selecteBGroup || 0,
                mobileUniqId: '',
                password: password || "",
                isMobileUser: checkboxMobileUserEmployeeAdd,
                isWebUser: checkboxWebUserEmployeeAdd,
                isActive: statusEmployeeAdd,
                branchList: selecteBranch.map(id => ({ branchId: id })),
                userOtherDetailsList: userDetailsList,
            };

            const existingAadharNumber = requestBody.aadharNumber || ''; // Replace with the current value from your state or props
            const existingAadharImage_Base64 = requestBody.aadharImage_Base64 || ''; // Replace with the current value from your state or props
            const existingAadharOriginalFileName = requestBody.aadharOriginalFileName || ''; // Replace with the current value from your state or props

            if (adharNo && (adharNo.inputValue || adharNo.fileBase64)) {
                // Set the AAadhaar Number only if inputValue exists
                if (adharNo.inputValue) {
                    requestBody.aadharNumber = adharNo.inputValue;
                } else {
                    // Keep the existing AAadhaar Number if no new value is provided
                    requestBody.aadharNumber = existingAadharNumber;
                }

                // Update file-related fields if a new file is uploaded
                if (adharNo.fileBase64) {
                    requestBody.aadharImage_Base64 = adharNo.fileBase64;
                    requestBody.aadharOriginalFileName = adharNo.fileName;
                } else {
                    // Maintain existing image data if no new file is uploaded
                    requestBody.aadharImage_Base64 = existingAadharImage_Base64;
                    requestBody.aadharOriginalFileName = existingAadharOriginalFileName;
                }
            } else {
                // If neither AAadhaar Number nor file is provided, clear all fields
                requestBody.aadharNumber = '';
                requestBody.aadharImage_Base64 = '';
                requestBody.aadharOriginalFileName = '';
            }

            const existingPanNumber = requestBody.panNumber || ''; // Replace with the current value from your state or props
            const existingPanImage_Base64 = requestBody.panCardImage_Base64 || ''; // Replace with the current value from your state or props
            const existingPanOriginalFileName = requestBody.panCardOriginalFileName || ''; // Replace with the current value from your state or props

            if (panNo && (panNo.inputValue || panNo.fileBase64)) {
                // Set the AAadhaar Number only if inputValue exists
                if (panNo.inputValue) {
                    requestBody.panNumber = panNo.inputValue;
                } else {
                    // Keep the existing AAadhaar Number if no new value is provided
                    requestBody.panNumber = existingPanNumber;
                }

                // Update file-related fields if a new file is uploaded
                if (panNo.fileBase64) {
                    requestBody.panCardImage_Base64 = panNo.fileBase64;
                    requestBody.panCardOriginalFileName = panNo.fileName;
                } else {
                    // Maintain existing image data if no new file is uploaded
                    requestBody.panCardImage_Base64 = existingPanImage_Base64;
                    requestBody.panCardOriginalFileName = existingPanOriginalFileName;
                }
            } else {
                // If neither AAadhaar Number nor file is provided, clear all fields
                requestBody.panNumber = '';
                requestBody.panCardImage_Base64 = '';
                requestBody.panCardOriginalFileName = '';
            }

            const existingOtherProofNumber = requestBody.otherProof || ''; // Replace with the current value from your state or props
            const existingOtherProofImage_Base64 = requestBody.otherProofImage_Base64 || ''; // Replace with the current value from your state or props
            const existingOtherProofOriginalFileName = requestBody.otherProofOriginalFileName || ''; // Replace with the current value from your state or props

            if (otherProof && (otherProof.inputValue || otherProof.fileBase64)) {
                // Set the AAadhaar Number only if inputValue exists
                if (otherProof.inputValue) {
                    requestBody.otherProof = otherProof.inputValue;
                } else {
                    // Keep the existing AAadhaar Number if no new value is provided
                    requestBody.otherProof = existingOtherProofNumber;
                }

                // Update file-related fields if a new file is uploaded
                if (otherProof.fileBase64) {
                    requestBody.otherProofImage_Base64 = otherProof.fileBase64;
                    requestBody.otherProofOriginalFileName = otherProof.fileName;
                } else {
                    // Maintain existing image data if no new file is uploaded
                    requestBody.otherProofImage_Base64 = existingOtherProofImage_Base64;
                    requestBody.otherProofOriginalFileName = existingOtherProofOriginalFileName;
                }
            } else {
                // If neither AAadhaar Number nor file is provided, clear all fields
                requestBody.otherProof = '';
                requestBody.otherProofImage_Base64 = '';
                requestBody.otherProofOriginalFileName = '';
            }

            if (profileImage && profileImage.base64) {
                requestBody.profileImage = '';
                requestBody.profileImage_Base64 = profileImage.base64 || "";
                requestBody.profileOriginalFileName = profileImage.name || "";

            } else {
                requestBody.profileImage = '';
                requestBody.profileOriginalFileName = '';
                requestBody.profileImage_Base64 = '';
            }

            if (selectedReportingEmployees) {
                requestBody.reportingTo = selectedReportingEmployees;
            }

            if (password) {
                requestBody.password = password;
            }
            console.log("api-responsee--->", requestBody);

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/User/SaveUser`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                setTimeout(() => {
                    // window.location.reload(); // Reload the page or fetch updated list
                    onCancel();
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing Employee Profile operation:", error);
            toast.error("Error performing Employee Profile operation");
        } finally {
            setIsLoading(false);
        }

    };

    // This transformation function should be defined appropriately based on your application's needs
    const userDetailsList = transformEntriesToUserDetailsList(entries);

    // This function is typically used in response to an event (like form submission)
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        handleEmployeeSubmit(e, userDetailsList);
    };

    const getUniqueOptions = (data, key, valueKey, labelKey) => {
        const unique = new Set();
        return data
            .filter(item => {
                if (!unique.has(item[key])) {
                    unique.add(item[key]);
                    return true;
                }
                return false;
            })
            .map(item => ({
                value: item[valueKey],
                label: item[labelKey]
            }));
    };

    const getStatesForCountry = () => {
        return getUniqueOptions(allTerData.filter(data => data.countryId === selectedCountry), 'stateId', 'stateId', 'stateName');
    };

    const getDistrictsForState = () => {
        return getUniqueOptions(allTerData.filter(data => data.stateId === selectedState), 'districtId', 'districtId', 'districtName');
    };

    const getStatesForCountryTemp = () => {
        return getUniqueOptions(allTerDataTemp.filter(data => data.countryId === selectedCountryTemp), 'stateId', 'stateId', 'stateName');
    };

    const getDistrictsForStateTemp = () => {
        return getUniqueOptions(allTerDataTemp.filter(data => data.stateId === selectedStateTemp), 'districtId', 'districtId', 'districtName');
    };

    const handleUploadChange = (data) => {
        console.log('Received data in handleUploadChange:', data);
        setAdharNo(data);
    };

    const handleUploadChangePan = (data) => {
        console.log('Received data in handleUploadChange pan no:', data);
        setPanNo(data);
    };

    const handleUploadChangeOther = (data) => {
        console.log('Received data in handleUploadChange other proof:', data);
        setOtherProof(data);
    };

    useEffect(() => {
        if (selectedEmployeeData) {
            const userData = selectedEmployeeData;
            setUname(selectedEmployeeData.userName);
            setMobile(selectedEmployeeData.mobileNumber);
            setEmail(selectedEmployeeData.emailId);
            setPassWord(selectedEmployeeData.password || "");
            setCompNo(selectedEmployeeData.companyNumber);
            setAddress1(selectedEmployeeData.addressLine);
            setAddress2(selectedEmployeeData.temporaryAddress);
            setPincode(selectedEmployeeData.pincode);
            setPincodeTow(selectedEmployeeData.temporary_Pincode);
            setEmgName(selectedEmployeeData.emergencyName);
            setEmgNo(selectedEmployeeData.emergencyContactNumber);
            setEmgRel(selectedEmployeeData.emergencyRelation);
            setSelectedGender(selectedEmployeeData.genderId);
            setSelectedGenderName(selectedEmployeeData.genderName);
            setSelectedDept(selectedEmployeeData.departmentId);
            setSelectedDeptName(selectedEmployeeData.departmentName);
            setSelectedRole(selectedEmployeeData.roleId);
            setSelectedRoleName(selectedEmployeeData.roleName);
            setSelectedCompany(selectedEmployeeData.companyId);
            setSelectedCompanyName(selectedEmployeeData.companyName);
            setSelectedBranch(userData.branchList.map(branch => branch.branchId));
            setSelectedBranchName(userData.branchList.map(branch => branch.branchName));
            setSelectedReportingEmployeesName(selectedEmployeeData.reportingToName);
            setSelectedMrgStatus(selectedEmployeeData.maritalStatusId);
            setSelectedMrgStatusName(selectedEmployeeData.maritalStatus);
            setSelectedBGroup(selectedEmployeeData.bloodGroupId);
            setSelectedBGroupName(selectedEmployeeData.bloodGroup);
            setStatusEmployeeAdd(selectedEmployeeData.isActive);
            setCheckboxWebUserEmployeeAdd(selectedEmployeeData.isWebUser);
            setCheckboxMobileUserEmployeeAdd(selectedEmployeeData.isMobileUser);
            setIsSameAsPermanent(selectedEmployeeData.isSameAsPermanent);
            setEntries(selectedEmployeeData.userOtherDetailsList);
            setreportingTo(selectedEmployeeData.reportingTo);

            const { dateOfBirth, dateOfJoining } = selectedEmployeeData;
            setDob(dateOfBirth.split("T")[0]);
            setDoj(dateOfJoining.split("T")[0]);

            setSelectedRegion(selectedEmployeeData.countryId);
            const selectedValue = selectedEmployeeData;
            setSelectedCountry(selectedValue.countryId);
            setSelectedState(selectedValue.stateId);
            setSelectedDistrict(selectedValue.districtId);
            setSelectedCountryName(selectedValue.countryName);
            setSelectedStateName(selectedValue.stateName);
            setSelectedDistrictName(selectedValue.districtName);

            setSelectedCountryTemp(selectedValue.temporary_CountryId);
            setSelectedStateTemp(selectedValue.temporary_StateId);
            setSelectedDistrictTemp(selectedValue.temporary_DistrictId);
            setSelectedCountryNameTemp(selectedValue.temporary_CountryName);
            setSelectedStateTempName(selectedValue.temporary_StateName);
            setSelectedDistrictTempName(selectedValue.temporary_DistrictName);

            setAdhaarImg(userData.aadharImageURL);
            setAdhaarName(userData.aadharOriginalFileName);
            setAdhaarNumber(userData.aadharNumber);

            setPanFileImg(selectedEmployeeData.panCardImageURL);
            setPanFileName(selectedEmployeeData.panCardOriginalFileName);
            setPanFileNumber(selectedEmployeeData.panNumber);

            setOtherFileImg(selectedEmployeeData.otherProofImageURL);
            setOtherFileName(selectedEmployeeData.otherProofOriginalFileName);
            setOtherFileNumber(selectedEmployeeData.otherProof);

            setFileOriginalName(selectedEmployeeData.profileOriginalFileName);
            setFileUrl(selectedEmployeeData.profileImageURL);
            console.log("lalalaa-->", reportingEmployees);
        }
    }, [selectedEmployeeData])

    return (
        <>
            <div className="bg-white min-w-[350px] pb-5 rounded-lg overflow-visible">
                <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                    <h1 className="text-l font-weight-[300]">Edit Employee</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="p-4 gap-4 max-h-[70vh] overflow-y-auto max-w-full w-[1100px]">
                        <Accordion title={"Employee Details"}>
                            <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
                                <div>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="uname" className="text-xs font-[400]">
                                            Employee Name<span className="text-red-500 gap-3">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            value={uname}
                                            className={`p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${cNameError ? 'border-red-500' : ''}`}
                                            id="uname"
                                            placeholder="Enter"
                                            onChange={(e) => handleChangeUname(e.target.value)}
                                            onBlur={(e) => validateCName(e.target.value)}
                                        />
                                        {cNameError && <span className="text-red-500 text-xs">{cNameError}</span>}
                                    </div>
                                </div>
                                <div>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="email" className="text-xs font-[400]">
                                            Email Id
                                            <span className="text-red-500 gap-3">*</span>
                                        </label>
                                        <input
                                            type="email"
                                            value={email}
                                            className={`p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${emailError ? 'border-red-500' : ''}`}
                                            id="email"
                                            placeholder="Enter"
                                            onChange={(e) => handleChangeEmail(e.target.value)}
                                            onBlur={(e) => validateEmail(e.target.value)}
                                        />
                                        {emailError && <span className="text-red-500 text-xs">{emailError}</span>}
                                    </div>
                                </div>
                                <div>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="mobile" className="text-xs font-[400]">
                                            Mobile Number<span className="text-red-500 gap-3">*</span>
                                        </label>
                                        <input
                                            type="tel"
                                            value={mobile}
                                            className={`p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${mobileError ? 'border-red-500' : ''}`}
                                            id="mobile"
                                            placeholder="Enter"
                                            onChange={(e) => handleChangeMobile(e.target.value)}
                                            onBlur={(e) => validateMobile(e.target.value)}
                                        />
                                        {mobileError && <span className="text-red-500 text-xs">{mobileError}</span>}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="id1" className="text-xs font-400 ">
                                        Company
                                    </label>
                                    {user?.roleId === 1 ? (
                                        <Select
                                            options={companyData.map(data => ({
                                                value: data.id,
                                                label: data.companyName
                                            }))}
                                            className="text-black text-xs select-bar"
                                            id="id1"
                                            isSearchable
                                            isClearable
                                            value={{ value: selecteCompany, label: selecteCompanyName }} // Set initial value here
                                            onChange={handleCompanyChange}
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#e5e7eb',
                                                },
                                            })}
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            value={selecteCompanyName}
                                            readOnly
                                            className="p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase text-gray-500"
                                        />
                                    )}
                                </div>
                                <div>
                                    <div className="flex flex-col gap-1">
                                        <CustomSelect2
                                            label={"Branch"}
                                            placeholder={"Select..."}
                                            isRequired={false}
                                            options={branchData.map(data => ({
                                                value: data.id,
                                                label: data.branchName
                                            }))}
                                            isMulti={true}
                                            value={selecteBranch.map((branchId, index) => ({
                                                value: branchId,
                                                label: selecteBranchName[index]
                                            }))}
                                            onChange={handleBranchChange}
                                            isClearable // Pass isClearable prop for conditional rendering
                                        />
                                        {branchError && (
                                            <span className="text-red-500 text-xs">{branchError}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-1">
                                    <CustomSelect2
                                        label={"Department"}
                                        placeholder={"Select Department"}
                                        options={deptData.map(data => ({
                                            value: data.id,
                                            label: data.departmentName
                                        }))}
                                        isRequired={false}
                                        value={{ value: selecteDept, label: selecteDeptName }} // Set initial value here
                                        onChange={handleDeptChange}
                                        isClearable // Pass isClearable prop for conditional rendering
                                    />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <CustomSelect2
                                        label={"Role"}
                                        placeholder={"Select Role"}
                                        isRequired={false}
                                        options={roleData.map(data => ({
                                            value: data.id,
                                            label: data.roleName
                                        }))}
                                        value={{ value: selecteRole, label: selecteRoleName }} // Set initial value here
                                        onChange={handleRoleChange}
                                        isClearable // Pass isClearable prop for conditional rendering
                                    />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <CustomSelect2
                                        label={"Reporting To"}
                                        placeholder={"Select..."}
                                        isRequired={false}
                                        options={reportingEmployees.map((employee) => ({
                                            value: employee.value,
                                            label: employee.text,
                                        }))}
                                        value={{ value: reportingTo, label: selectedReportingEmployeesName }} // Set initial value here
                                        onChange={handleReportingToChange}
                                        isClearable // Pass isClearable prop for conditional rendering
                                    />
                                </div>
                                <CustomDate label="Date of Birth"
                                    value={dob}
                                    onChange={setDob}
                                    isRequired={false}
                                />
                                <CustomDate label="Date of Joining"
                                    value={doj}
                                    onChange={setDoj}
                                    isRequired={false}
                                />
                                <div className="flex flex-col gap-1">
                                    <CustomSelect2
                                        label={"Blood Group"}
                                        placeholder={"Select..."}
                                        options={bGroupData.map(data => ({
                                            value: data.id,
                                            label: data.bloodGroup
                                        }))}
                                        isRequired={false}
                                        value={{ value: selecteBGroup, label: selecteBGroupName }} // Set initial value here
                                        onChange={handleBGroupChange}
                                        isClearable // Pass isClearable prop for conditional rendering
                                    />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <CustomSelect2
                                        label={"Gender"}
                                        placeholder={"Select..."}
                                        options={genderData.map(data => ({
                                            value: data.id,
                                            label: data.genderName
                                        }))}
                                        isRequired={false}
                                        value={{ value: selectedGender, label: selectedGenderName }} // Set initial value here
                                        onChange={handleGenderChange}
                                        isClearable // Pass isClearable prop for conditional rendering
                                    />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <CustomSelect2
                                        label={"Marital Status"}
                                        placeholder={"Select..."}
                                        options={mrgStatusData.map(data => ({
                                            value: data.id,
                                            label: data.maritalStatus
                                        }))}
                                        isRequired={false}
                                        value={{ value: selecteMrgStatus, label: selecteMrgStatusName }} // Set initial value here
                                        onChange={handleMrgStatusChange}
                                        isClearable // Pass isClearable prop for conditional rendering
                                    />
                                </div>
                                <CustomNumber label="Company Number" placeholder="Enter"
                                    value={compNo}
                                    onChange={setCompNo}
                                    isRequired={false}
                                />
                                <CustomPassword label="Password" placeholder="Enter" isRequired={false}
                                    value={password}
                                    onChange={setPassWord}
                                />
                            </div>
                        </Accordion>

                        <Accordion title="Permanent Address">
                            <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
                                <CustomTextArea label="Address" placeholder="Enter"
                                    value={address1}
                                    onChange={setAddress1}
                                    isRequired={false}
                                />
                                <div className="flex flex-col gap-1">
                                    <CustomSelect2
                                        label={"Country"}
                                        isRequired={false}
                                        placeholder={"Select..."}
                                        options={getUniqueOptions(allTerData, 'countryId', 'countryId', 'countryName')}
                                        value={{ value: selectedCountry, label: selectedCountryName }} // Set initial value here
                                        onChange={handleCountryChange}
                                        isClearable // Pass isClearable prop for conditional rendering
                                    />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <CustomSelect2
                                        label={"State"}
                                        placeholder={"Select..."}
                                        isRequired={false}
                                        options={selectedCountry ? getStatesForCountry() : []}
                                        value={{ value: selectedState, label: selectedStateName }} // Set initial value here
                                        onChange={handleStateChange}
                                        isClearable // Pass isClearable prop for conditional rendering
                                    />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <CustomSelect2
                                        label={"District / Province"}
                                        placeholder={"Select..."}
                                        isRequired={false}
                                        options={selectedState ? getDistrictsForState() : []}
                                        value={{ value: selectedDistrict, label: selectedDistrictName }} // Set initial value here
                                        onChange={handleDistrictChange}
                                        isClearable // Pass isClearable prop for conditional rendering
                                    />
                                </div>
                                <CustomPinCodeNumber label="Pin Code" placeholder="Enter"
                                    value={pincode}
                                    onChange={setPincode}
                                    isRequired={false}
                                />
                            </div>
                        </Accordion>

                        <Accordion title="Temporary Address">
                            <div className="flex justify-end">
                                <CustomCheckBox3
                                    label={"is Same As Permanent ?"}
                                    state={isSameAsPermanent}
                                    setState={setIsSameAsPermanent}
                                />
                            </div>
                            <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
                                <CustomTextArea label="Address" placeholder="Enter"
                                    value={address2}
                                    onChange={setAddress2}
                                    isRequired={false}
                                />
                                <div className="flex flex-col gap-1">
                                    <CustomSelect2
                                        label={"Country"}
                                        isRequired={false}
                                        placeholder={"Select..."}
                                        options={getUniqueOptions(allTerDataTemp, 'countryId', 'countryId', 'countryName')}
                                        value={{ value: selectedCountryTemp, label: selectedCountryNameTemp }} // Set initial value here
                                        onChange={handleCountryChangeTemp}
                                        isClearable // Pass isClearable prop for conditional rendering
                                    />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <CustomSelect2
                                        label={"State"}
                                        placeholder={"Select..."}
                                        isRequired={false}
                                        options={selectedCountryTemp ? getStatesForCountryTemp() : []}
                                        value={{ value: selectedStateTemp, label: selectedStateTempName }} // Set initial value here
                                        onChange={handleStateChangeTemp}
                                        isClearable // Pass isClearable prop for conditional rendering
                                    />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <CustomSelect2
                                        label={"District / Province"}
                                        placeholder={"Select..."}
                                        isRequired={false}
                                        options={selectedStateTemp ? getDistrictsForStateTemp() : []}
                                        value={{ value: selectedDistrictTemp, label: selectedDistrictTempName }} // Set initial value here
                                        onChange={handleDistrictChangeTemp}
                                        isClearable // Pass isClearable prop for conditional rendering
                                    />
                                </div>
                                <CustomPinCodeNumber label="Pin Code" placeholder="Enter"
                                    value={pincodeTwo}
                                    onChange={setPincodeTow}
                                    isRequired={false}
                                />
                            </div>
                        </Accordion>

                        <Accordion title="Emergency ">
                            <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
                                <CustomTextField2 label="Emergency Name" placeholder="Enter"
                                    value={emgName}
                                    onChange={setEmgName}
                                    isRequired={false}
                                />
                                <CustomNumber label="Emergency Contact" placeholder="Enter"
                                    value={emgNo}
                                    onChange={setEmgNo}
                                    isRequired={false}
                                />
                                <CustomTextField2 label="Emergency Relation" placeholder="Enter"
                                    value={emgRel}
                                    onChange={setEmgRel}
                                    isRequired={false}
                                />
                            </div>
                        </Accordion>

                        <Accordion title="Other Details">
                            {/* <OtherDetail /> */}
                            <div className="flex justify-end">
                                <Button
                                    variant={"contained"}
                                    size={"small"}
                                    type="button"
                                    className={"flex gap-1 capitalize justify-center items-center relative"}
                                    onClick={handleAddRow}
                                    style={{ backgroundColor: "#643c94" }}
                                >
                                    <FiPlus />
                                    <span className="whitespace-nowrap text-xs font-[400] font-Mitr">
                                        {entries ? "Add Row" : "Edit Row"}
                                    </span>
                                </Button>
                            </div>
                            <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
                                <div className="flex flex-col gap-1 relative">
                                    <label htmlFor="pastComp" className="text-xs font-[400] uppercase">
                                        Past Company Name
                                    </label>
                                    <input
                                        type="text"
                                        value={pastComp}
                                        className="p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase"
                                        id="pastComp"
                                        placeholder="Enter"
                                        onChange={handlePastCompChange}
                                    />
                                </div>
                                <div className="flex flex-col gap-1 relative">
                                    <label htmlFor="totalExp" className="text-xs font-[400] uppercase">
                                        Total Exp
                                    </label>
                                    <input
                                        type="text"
                                        value={totalExp}
                                        className="p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase"
                                        id="totalExp"
                                        placeholder="Enter"
                                        onChange={handleTotalExpChange}
                                    />
                                    {totalExpErr && (
                                        <p className="absolute -bottom-4 text-xs font-normal" style={{ color: "red" }}>
                                            {totalExpErr}
                                        </p>
                                    )}
                                </div>
                                <div className="flex flex-col gap-1 relative">
                                    <label htmlFor="remark" className="text-xs font-[400] uppercase">
                                        Remark
                                    </label>
                                    <input
                                        type="text"
                                        value={remark}
                                        className="p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase"
                                        id="remark"
                                        placeholder="Enter"
                                        onChange={handleRemarkChange}
                                    />
                                </div>
                            </div>
                            <div className="w-full mb-4 mt-4 overflow-hidden rounded">
                                <div className="w-full overflow-x-auto">
                                    <table className="w-full">
                                        <thead className="text-center">
                                            <tr className="text-center text-md text-gray-900 bg-gray-100 uppercase border border-gray-200 border-collapse">
                                                <td className="text-xs py-2 border border-gray-200">S. No</td>
                                                <td className="text-xs py-2 border border-gray-200">Past Company Name</td>
                                                <td className="text-xs py-2 border border-gray-200">Total Exp</td>
                                                <td className="text-xs py-2 border border-gray-200">Remark</td>
                                                <td className="text-xs py-2 border border-gray-200">Action</td>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            {entries.map((entry, index) => (
                                                <tr key={index} className="text-xs text-center border border-gray-200 border-collapse text-gray-600">
                                                    <td className="text-xs border border-gray-200">{index + 1}</td>
                                                    <td className="text-xs border border-gray-200">{entry.pastCompanyName}</td>
                                                    <td className="text-xs border border-gray-200">{entry.totalExp}</td>
                                                    <td className="text-xs border border-gray-200">{entry.remark}</td>
                                                    <td className="flex items-center justify-center gap-2">
                                                        <button className="bg-prp-color text-white m-1 p-1 rounded" type="button" onClick={() => handleEditRow(index)}>
                                                            <Tooltip title="Edit" arrow placement="left">
                                                                <span>
                                                                    <FiEdit />
                                                                </span>
                                                            </Tooltip>
                                                        </button>
                                                        <button className="bg-red-500 text-white m-1 p-1 rounded" type="button" onClick={() => handleDeleteRow(index)}>
                                                            <Tooltip title="Delete" arrow placement="right">
                                                                <span>
                                                                    <RiDeleteBinLine />
                                                                </span>
                                                            </Tooltip>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">
                                <CustomGlobalUpload
                                    id="addhar-number-edi"
                                    label="Aadhaar Number"
                                    value={adharNo.inputValue}
                                    fileText={adhaarNumber}
                                    fileOriginalName={adhaarName}
                                    fileUrl={adhaarImg}
                                    onChange={handleUploadChange}
                                    isRequired={false}
                                />

                                <CustomGlobalUpload
                                    id="pan-upload-edi"
                                    label="PAN No."
                                    value={panNo.inputValue}
                                    fileText={panFileNumber}
                                    fileOriginalName={panFileName}
                                    fileUrl={panFileImg}
                                    onChange={handleUploadChangePan}
                                    isRequired={false}
                                />

                                <CustomGlobalUpload
                                    id="other-proof-upload-edi"
                                    label="Other Proof"
                                    value={otherProof.inputValue}
                                    fileText={otherFileNumber}
                                    fileOriginalName={otherFileName}
                                    fileUrl={otherFileImg}
                                    onChange={handleUploadChangeOther}
                                    isRequired={false}
                                />
                                <div>
                                    <CustomImgUpload
                                        id="pi-upload-add-v13"
                                        label="Image Upload"
                                        value={profileImage}
                                        onChange={setProfileImage}
                                        initialFileName={fileOriginalName}
                                        initialFileUrl={fileUrl}
                                        isRequired={false}
                                    />
                                </div>
                                <CustomCheckBox3
                                    label={"Is Web User"}
                                    state={checkboxWebUserEmployeeAdd}
                                    setState={setCheckboxWebUserEmployeeAdd}
                                >
                                </CustomCheckBox3>
                                <CustomCheckBox3
                                    label={"Is Mobile User"}
                                    state={checkboxMobileUserEmployeeAdd}
                                    setState={setCheckboxMobileUserEmployeeAdd}
                                >
                                </CustomCheckBox3>
                                <CustomCheckBox3
                                    label={"Is Active"}
                                    state={statusEmployeeAdd}
                                    setState={setStatusEmployeeAdd}
                                ></CustomCheckBox3>
                            </div>
                        </Accordion>
                    </div>

                    <div className="flex justify-center gap-5 mt-5">
                        <div>
                            <CustomButton1 label={"Update"} className="text-white bg-first" type="submit" />
                        </div>
                        <div onClick={onCancel}>
                            <CustomButton2 label={"Cancel"} className="text-white bg-first" />
                        </div>
                    </div>
                </form>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}
