// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import PopUp from "../../general-components/PopUp.componenet";
import Pagination from "../../general-components/Pagination.component";
import CustomButton4 from "../../general-components/CustomButton4.component";

// ICONS
import { IoMdCloseCircle } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { BiEdit, BiLike, BiDislike } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";
import { getSinglePermission } from "../../../../Helpers/Constants";
import { useAuth } from "../../../context";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AllLeadsEdit from '../../forms-components/PendingLeads/PendingLeadsEdit'
import AllLeadsView from '../../forms-components/PendingLeads/PendingLeadsView'
import LeadAccept from "../../forms-components/LeadAcceptCancel/LeadAccept";
import LeadCancel from "../../forms-components/LeadAcceptCancel/LeadCancel";

export default function PendingLeads() {
    const { user } = useAuth()
    const [Permission, setPermission] = useState({ add: true, view: true, edit: true })

    useEffect(() => {
        if (Number(user?.roleId) !== 1) {
            setPermission(getSinglePermission(user?.userRoleList, "MANAGE LEADS"))
        }
    }, [user])
    let [popupEdit, setPopupEdit] = useState(false);
    let [popupView, setPopupView] = useState(false);
    let [popupAccept, setPopupAccept] = useState(false);
    let [popupCancel, setPopupCancel] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [inputData, setInputData] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [customerId, setCustomerId] = useState("")

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const [searchText, setSearchText] = useState("");

    const fetchApiData = async (searchQuery) => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Customer/GetCustomerList`,
                //  data, 
                { searchText: searchQuery, leadStatusId: 1, customerId: 0, parentCustomerId: 0, countryId: 0 }, // Include searchText in the request body
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                // First, sort by isActive, then by createdDate
                const sortedData = response.data.data.sort((a, b) => {
                    if (a.isActive === b.isActive) {
                        return new Date(b.createdDate) - new Date(a.createdDate);
                    }
                    return a.isActive === true ? -1 : 1;
                });

                setInputData(sortedData);
                setTotalPages(Math.ceil(sortedData.length / rowsPerPage));
                setCurrentPage(1);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching BRC Data");
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditButtonClick = (id) => {
        fetchApiDataById(id);
        setPopupEdit(true);
    };

    const handleViewButtonClick = (id) => {
        fetchApiDataById(id);
        setPopupView(true);
    };

    const handleAcceptButtonClick = (id) => {
        fetchApiDataById(id);
        setPopupAccept(true);
    };

    const handleRejectButtonClick = (id) => {
        fetchApiDataById(id);
        setPopupCancel(true);
    };

    const fetchApiDataById = async (id) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/Customer/GetCustomerById?Id=${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.isSuccess && response.data.data) {
                setSelectedValue(response.data.data);
                setCustomerId(response.data.data.id);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Manage BRC details");
        } finally {
            setIsLoading(false);
        }
    };

    const paginatedData = inputData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    useEffect(() => {
        fetchApiData(searchText);
    }, [rowsPerPage, searchText]);

    useEffect(() => {
        setTotalPages(Math.ceil(inputData.length / rowsPerPage));
    }, [inputData, rowsPerPage]);

    const clearSearch = () => {
        setSearchText(""); // Clear the search input
    };

    return (
        <>
            {/* 4 BUTTONS + TABLE */}
            <div className="">
                {/* 4 BUTTONS */}
                <div className="flex flex-row flex-wrap justify-start gap-2  md:flex-nowrap minbox">
                    <div className="flex items-center justify-between gap-1 px-2 ml-auto bg-white shrink grow md:grow-0">
                        <input
                            type="text"
                            className="w-[250px] py-1  grow text-sm uppercase bg-transparent placeholder:text-sm"
                            placeholder="SEARCH"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        {searchText ? (
                            <IoMdCloseCircle
                                className="min-w-[20px] cursor-pointer txt-prp-color"
                                onClick={clearSearch}
                            />
                        ) : (
                            <AiOutlineSearch className="min-w-[20px]" />
                        )}
                    </div>
                </div>

                {
                    <>
                        {/* TABLE */}
                        <div className="mt-7 max-w-[100vw] overflow-auto table-container">
                            <table className="w-full custom-table" border={1}>
                                <thead>
                                    <tr className="table-heading">
                                        <td className="">S.No.</td>
                                        <td>Action</td>
                                        <td>Status</td>
                                        <td>Customer Name</td>
                                        <td>Contact Name</td>
                                        <td>Mobile #</td>
                                        <td>Email Id</td>
                                        <td>Created Date </td>
                                        <td>Created By </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ? (
                                        <tr>
                                            <td colSpan="11" className="text-center">Loading...</td>
                                        </tr>
                                    ) : (
                                        paginatedData && paginatedData && paginatedData.length > 0 ? (
                                            paginatedData.map((cData, index) => (
                                                <tr key={cData.id}>
                                                    <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                                                    <td>
                                                        <div className="flex gap-1">
                                                            {Permission?.edit && (
                                                                <Tooltip title="EDIT" arrow placement="left">
                                                                    <span>
                                                                        <CustomButton1
                                                                            icon={<BiEdit />}
                                                                            className="bg-sixt text-white grow max-w-[30px]"
                                                                            onClick={() => handleEditButtonClick(cData.id)}
                                                                        />
                                                                    </span>
                                                                </Tooltip>
                                                            )}
                                                            <Tooltip title="ACCEPT" arrow placement="bottom">
                                                                <span>
                                                                    <Button
                                                                        variant={"contained"}
                                                                        size={"small"}
                                                                        className={`bg-green-500 text-white grow max-w-[30px] flex gap-2 justify-center items-center relative uppercase`}
                                                                        onClick={() => handleAcceptButtonClick(cData.id)}
                                                                    >
                                                                        <BiLike />
                                                                    </Button>
                                                                </span>
                                                            </Tooltip>

                                                            <Tooltip title="REJECT" arrow placement="bottom">
                                                                <span>
                                                                    <Button
                                                                        variant={"contained"}
                                                                        size={"small"}
                                                                        className={`bg-red-500 text-white grow max-w-[30px] flex gap-2 justify-center items-center relative uppercase`}
                                                                        onClick={() => handleRejectButtonClick(cData.id)}
                                                                    >
                                                                        <BiDislike />
                                                                    </Button>
                                                                </span>
                                                            </Tooltip>

                                                            {Permission?.view && (
                                                                <Tooltip title="VIEW" arrow placement="right">
                                                                    <span>
                                                                        <CustomButton4
                                                                            className="bg-eye text-white grow max-w-[30px]"
                                                                            icon={<BsEyeFill />}
                                                                            onClick={() => handleViewButtonClick(cData.id)}
                                                                        />
                                                                    </span>
                                                                </Tooltip>
                                                            )}

                                                        </div>
                                                    </td>
                                                    <td>
                                                        {/* {cData.leadStatus} */}
                                                        <h2 style={{ color: 'orange' }}>
                                                            {cData.leadStatus} {/* Default to 'Pending' if leadStatus is undefined */}
                                                        </h2>
                                                    </td>
                                                    <td>{cData.customerName}</td>
                                                    <td>{cData.contactName}</td>
                                                    <td>{cData.mobileNo}</td>
                                                    <td>{cData.emailId}</td>
                                                    <td>{new Date(cData.createdDate).toLocaleDateString('en-GB')}</td>
                                                    <td>{cData.creatorName}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            searchText?.trim() ? (
                                                <tr>
                                                    <td colSpan="9" className="text-center py-4">
                                                        No results found for "{searchText}".
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td colSpan="9" className="text-center py-4">
                                                        No data available.
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    )}
                                </tbody>

                            </table>
                        </div>

                        <Pagination
                            currentPage={currentPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                            data={inputData}
                        />
                    </>
                }
            </div>

            {popupEdit && (
                <PopUp>
                    <AllLeadsEdit
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupEdit(false)
                            fetchApiData()
                        }}
                    />
                </PopUp>
            )}

            {popupView && (
                <PopUp>
                    < AllLeadsView
                        selectedValue={selectedValue}
                        onCancel={() => setPopupView(false)}
                    />
                </PopUp>
            )}

            {popupAccept && (
                <PopUp>
                    <LeadAccept
                        customerId={customerId}
                        onCancel={() => setPopupAccept(false)}
                    />
                </PopUp>
            )}

            {popupCancel && (
                <PopUp>
                    <LeadCancel
                        customerId={customerId}
                        onCancel={() => setPopupCancel(false)}
                    />
                </PopUp>
            )}

            <ToastContainer autoClose={2000} />
        </>
    )
}