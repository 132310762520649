import React, { useState } from 'react';
import { ImEnlarge } from 'react-icons/im';
import { MdCancel } from 'react-icons/md';
import { FaDownload } from 'react-icons/fa';
import Modal from 'react-modal';

const CustomInputWithFileViewer = ({ fileURL, fileNumber, fileName, label }) => {
    const fullFileURL = fileURL && fileName ? `${fileURL}${fileName}` : null;

    const handleDownload = () => {
        if (!fullFileURL) return;
        const link = document.createElement('a');
        link.href = fullFileURL;
        link.download = fileName || "downloaded_file";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleEnlarge = () => {
        if (fullFileURL) window.open(fullFileURL, '_blank');
    };

    const getFileExtension = (filename) => {
        if (!filename) return "";
        return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
    };

    const isPDF = fullFileURL && getFileExtension(fileName).toLowerCase() === 'pdf';

    return (
        <div className="flex flex-col gap-1 relative">
            <label className="text-xs font-[400]">
                {label.toUpperCase()}
            </label>
            <div className="flex px-2 justify-between items-center">
                <div className="flex flex-col">
                    <p className="text-gray-400 text-xs">{fileNumber || "N/A"}</p>
                    <p className="text-gray-400 text-xs">{fileName || "No File Name"}</p>
                </div>

                <div className="flex items-center space-x-2">
                    {fullFileURL ? (
                        <>
                            <button onClick={handleDownload} className="bg-white txt-prp-color border border-prp p-1.5 rounded">
                                <FaDownload />
                            </button>
                            <button onClick={handleEnlarge} className="bg-white txt-prp-color border border-prp p-1.5 rounded">
                                <ImEnlarge />
                            </button>
                        </>
                    ) : (
                        null
                    )}
                </div>

                <div>
                    {fullFileURL ? (
                        isPDF ? (
                            <embed
                                src={fullFileURL}
                                type="application/pdf"
                                className="w-10 h-10 border border-gray-300"
                            />
                        ) : (
                            <img
                                src={fullFileURL}
                                alt="Preview"
                                className="w-10 h-10 rounded-full border border-gray-300"
                            />
                        )
                    ) : (
                        <div className="flex w-10 h-10 rounded-full border border-gray-300">
                            <span className="text-gray-400 text-center text-xs flex items-center justify-center">
                                No Preview
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};


export default CustomInputWithFileViewer;
