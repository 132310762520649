import React from 'react';
import { ImEnlarge } from 'react-icons/im';
import { FaDownload } from 'react-icons/fa';

const CustomFileViewer = ({ fileURL, fileName, label }) => {
  // Provide a fallback name if fileName is null or empty
  const displayFileName = fileName || "N/A";

  const handleEnlarge = () => {
    if (fileURL) {
      window.open(fileURL, '_blank');
    }
  };

  const handleDownload = () => {
    if (fileURL) {
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = displayFileName; // Use fallback name for download
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const getFileExtension = (filename) => {
    if (!filename) return ""; // Handle null or empty filenames
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
  };

  const isPDF = fileURL && getFileExtension(fileName).toLowerCase() === 'pdf';

  return (
    <div className="flex flex-col gap-1 relative">
      <label className="text-xs font-[400]">
        {label.toUpperCase()}
      </label>
      <div className="flex px-2 justify-between items-center">
        <p className="text-gray-400 text-xs">{displayFileName}</p>
        {fileURL && (
          <div className="flex items-center space-x-2">
            <button
              onClick={handleDownload}
              className="bg-white txt-prp-color border border-prp p-1.5 rounded"
            >
              <FaDownload />
            </button>
            <button
              onClick={handleEnlarge}
              className="bg-white txt-prp-color border border-prp p-1.5 rounded"
            >
              <ImEnlarge />
            </button>
          </div>
        )}
        <div>
          {!fileURL && (
            <div className="flex w-10 h-10 rounded-full border border-gray-300">
              <span className="text-gray-400 text-center text-xs flex items-center justify-center">
                No Preview
              </span>
            </div>
          )}
          {fileURL && isPDF ? (
            <embed
              src={fileURL}
              type="application/pdf"
              className="w-10 h-10 border border-gray-300"
            />
          ) : (
            <img
              src={fileURL}
              alt="Preview"
              className="w-10 h-10 rounded-full border border-gray-300"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomFileViewer;
