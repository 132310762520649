import Icon1 from "../../assets/1.svg";
import Icon2 from "../../assets/22.svg";
import Icon5 from "../../assets/5.svg";
// import Icon5Light from "../../assets/5.5.svg";
import Icon9 from "../../assets/9.svg";
// import Icon9Light from "../../assets/9.9.svg";
import Icon7 from "../../assets/7.svg";
// import Icon7Light from "../../assets/7.5.svg";
import Icon3 from "../../assets/3.svg";
// import Icon3Light from "../../assets/3.5.svg";
// import Icon1 from "../../assets/1.svg";
import dashIcon from "../../assets/Dashboard.svg";
import admin from "../../assets/Administration.svg";
import { FaChartBar, FaClipboard, FaFileAlt, FaRegIdBadge } from "react-icons/fa";
export const ModulesIds = {
    DASHBOARD: 1,
    ADMIN_PROFILE: 3,
    MANAGE_ADMIN_TERRITORY: 4,
    ADMIN_GENDER: 5,
    ADMIN_COMPANY: 6,
    ADMIN_BRANCH: 7,
    ADMIN_BLOODGROUP: 8,
    ADMIN_MARITAL_STATUS: 9,
    ADMIN_COMPANYTYPE: 10,
    ADMIN_CUSTOMERTYPE: 11,
    ADMIN_PORTDISCHARGE: 12,
    ADMIN_PAPERTYPE: 13,
    ADMIN_TYPE_OF_PACKAGING: 14,
    ADMIN_UOM: 15,
    ADMIN_PACKAGING_TYPE: 16,
    ADMIN_CONTAINER_TYPE: 17,
    ADMIN_CURRENCY_TYPE: 18,
    ADMIN_DELIVERY_TERMS: 19,
    ADMIN_PAYMENT_TERMS: 20,
    ADMIN_PRODUCTION_STATUS: 21,
    ADMIN_TRANSPORTER: 48,
    ADMIN_FORWARDER: 49,
    ADMIN_CHA: 50,
    ADMIN_BRC: 22,
    ADMIN_FORWARDING: 23,
    ADMIN_BANK:51,
    ADMIN_PORT:52,
    ADMIN_TRACKING_STATUS: 24,
    MANAGE_LEADS: 25,
    MANAGE_CUSTOMER: 26,
    MANAGE_TRACKING_STATUS: 27,
    ADMIN_PAYMENT_RECEIVED: 28,
    MANAGE_TRACKING_MANAGE_PO_RECEIVED: 29,
    MANAGE_TRACKING_MANAGE_PI_ISSUED: 30,
    MANAGE_TRACKING_MANAGE_PI_CONFIRMATION: 31,
    MANAGE_TRACKING_PAYMENT_RECEIVED_LC_RECEIVED: 32,
    MANAGE_TRACKING_ORDER_ACCEPTED: 33,
    MANAGE_TRACKING_ORDER_UNDER_PROCESS: 34,
    MANAGE_TRACKING_BOOKING_ISSUE: 35,
    MANAGE_TRACKING_CONTAINERS_UNDER_LOADING: 36,
    MANAGE_TRACKING_INVOICE_GENERATED: 37,
    MANAGE_TRACKING_BI_DRAFT_ISSUED: 38,
    MANAGE_TRACKING_FINAL_BI_ISSUED: 39,
    MANAGE_TRACKING_FINAL_AMOUNT_TO_PAY: 40,
    MANAGE_TRACKING_DOCUMENT_SEND_THROUGH_DHL_SUBMITTED: 46,
    MANAGE_TRACKING_AWB_GENERATED_PENDING: 42,
    MANAGE_TRACKING_ORDER_COMPLETED_PENDING: 43,
    MANAGE_TRACKING_PAYMENT_RECEIVED: 43,
    MANAGE_TRACKING_PO_CLOSED: 47,
};

export const TabArray = [
    {
        index: 0,
        activeIcon: <img src={dashIcon} className="icon text-inherit ring-first" alt="" />,
        inActiveIcon: <img src={Icon1} className="icon text-inherit ring-first" alt="" />,
        title: "DASHBOARD",
        subTabs: {
            title: "Dashboard",
            path: "dashboard",
        },
        path: "app/dashboard",
        disabled: true, // Example disabled state
        to: "app/dashboard", // Example link
        id: ModulesIds.DASHBOARD,
    },
    {
        index: 2,
        activeIcon: <img src={admin} className="icon text-inherit ring-first" alt="" />,
        inActiveIcon: <img src={Icon2} className="icon text-inherit ring-first" alt="" />,
        title: "ADMINISTRATOR",
        to: "admin-profile", // Example link
        admin: true,
        id: 0,
        // path: "admin",
        subTabs: [
            { title: "Admin Profile", path: "admin-profile", id: ModulesIds.ADMIN_PROFILE },
            {
                title: "MANAGE ADMIN TERRITORY",
                to: "admin-manage-territories/admin-attributes",
                path: "admin-manage-territories/admin-attributes",
                subsub: true,
                subId: [ModulesIds.MANAGE_ADMIN_TERRITORY],
                subTabs: [
                    {
                        title: "Admin Attributes",
                        path: "admin-manage-territories/admin-attributes",
                        id: ModulesIds.MANAGE_ADMIN_TERRITORY,
                    },
                    {
                        title: "Admin Territories",
                        path: "admin-manage-territories/admin-territories",
                        id: ModulesIds.MANAGE_ADMIN_TERRITORY,
                    },
                ],
            },
            { title: "Admin Gender", path: "admin-gender", id: ModulesIds.ADMIN_GENDER },
            { title: "Admin Customer Type", path: "admin-customer-type", id: ModulesIds.ADMIN_CUSTOMERTYPE },
            { title: "Admin Company", path: "admin-company-details", id: ModulesIds.ADMIN_COMPANY },
            { title: "Admin Branch", path: "admin-Branch-details", id: ModulesIds.ADMIN_BRANCH },
            { title: "Admin Port Discharge", path: "admin-port-discharge", id: ModulesIds.ADMIN_PORTDISCHARGE },
            { title: "Admin Paper type", path: "admin-paper-type", id: ModulesIds.ADMIN_PAPERTYPE },
            {
                title: "Admin Type Of Packaging",
                path: "admin-type-of-packaging",
                id: ModulesIds.ADMIN_TYPE_OF_PACKAGING,
            },
            { title: "Admin UoM", path: "admin-uom", id: ModulesIds.ADMIN_UOM },
            // { title: "Admin Packaging Type", path: "admin-packaging-type", id: ModulesIds.ADMIN_PACKAGING_TYPE },
            { title: "Admin Container Type", path: "admin-container-type", id: ModulesIds.ADMIN_CONTAINER_TYPE },
            { title: "Admin Currency Type", path: "admin-currency-type", id: ModulesIds.ADMIN_CURRENCY_TYPE },
            { title: "Admin Delivery Terms", path: "admin-delivery-terms", id: ModulesIds.ADMIN_DELIVERY_TERMS },
            { title: "Admin Payment Terms", path: "admin-payment-terms", id: ModulesIds.ADMIN_PAYMENT_TERMS },
            {
                title: "Admin Production Status",
                path: "admin-production-status",
                id: ModulesIds.ADMIN_PRODUCTION_STATUS,
            },
            { title: "Admin BRC", path: "admin-brc", id: ModulesIds.ADMIN_BRC },
            { title: "Admin Forwarding", path: "admin-forwarding", id: ModulesIds.ADMIN_FORWARDING },
            { title: "Admin Tracking Status", path: "admin-tracking-status", id: ModulesIds.ADMIN_TRACKING_STATUS },
            { title: "Admin Blood Group", path: "admin-blood-group", id: ModulesIds.ADMIN_BLOODGROUP },
            { title: "Admin Marital Status", path: "admin-marital-status", id: ModulesIds.ADMIN_MARITAL_STATUS },
            { title: "Admin Payment Received", path: "admin-payment-received", id: ModulesIds.ADMIN_PAYMENT_RECEIVED },
            { title: "Admin Transporter", path: "admin-transporter", id: ModulesIds.ADMIN_TRANSPORTER },
            { title: "Admin Forwader", path: "admin-forwader", id: ModulesIds.ADMIN_FORWARDER },
            { title: "Admin CHA", path: "admin-cha", id: ModulesIds.ADMIN_CHA },
            { title: "Admin Bank Name", path: "admin-bank", id: ModulesIds.ADMIN_BANK },
            // { title: "Admin Reuse/Fresh", path: "admin-reuse-refresh", id: ModulesIds.ADMIN_CHA },
            { title: "Admin Port", path: "admin-port-code", id: ModulesIds.ADMIN_PORT },
            { title: "Admin Version Details", path: "admin-version-details", id: ModulesIds.ADMIN_BANK },
        ],
    },
    {
        index: 3,
        activeIcon: <img src={Icon5} className="icon text-inherit ring-first" alt="" />,
        inActiveIcon: <img src={Icon5} className="icon text-inherit ring-first" alt="" />,
        title: "Manage Leads",
        to: "app/leads",
        path: "app/leads",
        subTabs: {
            title: "Manage leads",
            path: "leads",
        },
        id: ModulesIds.MANAGE_LEADS,
    },
    {
        index: 4,
        activeIcon: <img src={Icon3} className="icon text-inherit ring-first" alt="" />,
        inActiveIcon: <img src={Icon3} className="icon text-inherit ring-first" alt="" />,
        title: "Manage Customers",
        to: "app/customers",
        path: "app/customers",
        subTabs: {
            title: "Manage Customers",
            path: "customers",
        },
        id: ModulesIds.MANAGE_CUSTOMER,
    },
    {
        index: 5,
        activeIcon: <img src={Icon9} className="icon text-inherit ring-first" alt="" />,
        inActiveIcon: <img src={Icon9} className="icon text-inherit ring-first" alt="" />,
        title: "Manage Tracking Status",
        to: "app/manage-tracking-status",
        path: "app/manage-tracking-status",
        subTabs: {
            title: "Manage Tracking Status",
            path: "manage-tracking-status",
        },
        id: ModulesIds.MANAGE_TRACKING_STATUS,
    },
    {
        index: 6,
        activeIcon: <img src={Icon7} className="icon text-inherit ring-first" alt="" />,
        inActiveIcon: <img src={Icon7} className="icon text-inherit ring-first" alt="" />,
        title: "Manage Tracking",
        to: "app/manage-po-received",
        path: "app",
        sub: true,
        id: 0,
        subTabs: [
            {
                title: "Manage PO Received",
                path: "manage-po-received",
                id: ModulesIds.MANAGE_TRACKING_MANAGE_PO_RECEIVED,
                to: "app/manage-po-received",
            },
            {
                title: "Manage PI Issued",
                path: "manage-pi-issued",
                id: ModulesIds.MANAGE_TRACKING_MANAGE_PI_ISSUED,
                to: "app/manage-pi-issued",
            },
            {
                title: "Manage PI Confirmation",
                path: "manage-pi-confirmation",
                to: "app/manage-pi-confirmation",
                id: ModulesIds.MANAGE_TRACKING_MANAGE_PI_CONFIRMATION,
            },
            {
                title: "Advance Payment / LC",
                path: "direct-payment-lc",
                to: "app/direct-payment-lc",
                id: ModulesIds.MANAGE_TRACKING_PAYMENT_RECEIVED_LC_RECEIVED,
            },
            {
                title: "Order Accepted",
                path: "manage-order-accepted",
                to: "app/manage-order-accepted",
                id: ModulesIds.MANAGE_TRACKING_ORDER_ACCEPTED,
            },
            {
                title: "Order Under Process",
                path: "manage-order-under-process",
                to: "app/manage-order-under-process",
                id: ModulesIds.MANAGE_TRACKING_ORDER_UNDER_PROCESS,
            },
            {
                title: "Booking Issued",
                path: "manage-booking-issue",
                to: "app/manage-booking-issue",
                id: ModulesIds.MANAGE_TRACKING_BOOKING_ISSUE,
            },
            {
                title: "Containers Under Loading",
                path: "manage-containers-under-loading",
                to: "app/manage-containers-under-loading",
                id: ModulesIds.MANAGE_TRACKING_CONTAINERS_UNDER_LOADING,
            },
            {
                title: "Invoice Generated",
                path: "manage-invoice-generated",
                to: "app/manage-invoice-generated",
                id: ModulesIds.MANAGE_TRACKING_INVOICE_GENERATED,
            },

            {
                title: "BL Draft Issued",
                path: "manage-bl-draft-issued",
                to: "app/manage-bl-draft-issued",
                id: ModulesIds.MANAGE_TRACKING_BI_DRAFT_ISSUED,
            },
            {
                title: "Final BL Issued",
                path: "manage-bl-issued",
                to: "app/manage-bl-issued",
                id: ModulesIds.MANAGE_TRACKING_FINAL_BI_ISSUED,
            },
            {
                title: "Final Amount to Pay",
                path: "manage-final-amount-to-pay",
                to: "app/manage-final-amount-to-pay",
                id: ModulesIds.MANAGE_TRACKING_FINAL_AMOUNT_TO_PAY,
            },
            {
                title: "Document Send Through DHL / Submitted",
                path: "manage-document-send-dhl-submitted",
                to: "app/manage-document-send-dhl-submitted",
                id: ModulesIds.MANAGE_TRACKING_DOCUMENT_SEND_THROUGH_DHL_SUBMITTED,
            },
            {
                title: "Final Payment Received",
                path: "manage-payment-received",
                to: "app/manage-payment-received",
                id: ModulesIds.MANAGE_TRACKING_PAYMENT_RECEIVED,
            },
            {
                title: "Manage PO closed",
                path: "manage-awb-generated-pending",
                to: "app/manage-awb-generated-pending",
                id: ModulesIds.MANAGE_TRACKING_PO_CLOSED,
            },
            {
                title: "Manage Commission",
                path: "manage-commission",
                to: "app/manage-commission",
                id: ModulesIds.MANAGE_TRACKING_ORDER_COMPLETED_PENDING,
            },
            {
                title: "Order Completed",
                path: "manage-order-completed-pending",
                to: "app/manage-order-completed-pending",
                id: ModulesIds.MANAGE_TRACKING_ORDER_COMPLETED_PENDING,
            },
        ],
    },

    {
        index: 7,
        activeIcon: <FaChartBar className="icon text-inherit ring-first text-2xl" />,
        inActiveIcon: <FaChartBar className="icon text-inherit ring-first text-2xl" />,
        // activeIcon:<img stc={FaRegIdBadge} className="icon text-inherit ring-first text-2xl" alt="" />,
        title: "Manage MIS",
        to: "app/manage-pi-tracking-report",
        path: "app",
        sub: true,
        id: 0,
        subTabs: [
            {
                title: "MIS Pi Tracking",
                path: "manage-pi-tracking-report",
                id: ModulesIds.MANAGE_TRACKING_MANAGE_PO_RECEIVED,
                to: "app/manage-pi-tracking-report",
            },
            {
                title: "MIS Advance Amount",
                path: "manage-advance-amount-report",
                id: ModulesIds.MANAGE_TRACKING_MANAGE_PO_RECEIVED,
                to: "app/manage-advance-amount-report",
            },
            {
                title: "MIS FINAL INVOICE & PAYMENT RECORD",
                path: "manage-final-invocie-payment-record-report",
                id: ModulesIds.MANAGE_TRACKING_MANAGE_PO_RECEIVED,
                to: "app/manage-final-invocie-payment-record-report",
            },
            {
                title: "MIS SHIPPING BILL RECORD",
                path: "manage-s-bill-record-report",
                id: ModulesIds.MANAGE_TRACKING_MANAGE_PO_RECEIVED,
                to: "app/manage-s-bill-record-report",
            },
            {
                title: "MIS Commission",
                path: "commission-report",
                id: ModulesIds.MANAGE_TRACKING_MANAGE_PO_RECEIVED,
                to: "app/commission-report",
            },
            {
                title: "MIS Sell Rate",
                path: "manage-sell-rate-report",
                id: ModulesIds.MANAGE_TRACKING_MANAGE_PO_RECEIVED,
                to: "app/manage-sell-rate-report",
            },
        ],
    },
];
